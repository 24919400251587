import React from "react";
import "./Work.scss";
import Header from "../layouts/Header";
import HobbyCard from "../components/HobbyCard";
import WorkplaceCard from "../components/WorkplaceCard";

import populumLogo from "../assets/populumLogo.webp";
import axisLogo from "../assets/axisLogo.webp";
import personalkollenLogo from "../assets/personalkollenLogo.webp";

import gitTrendImage from "../assets/projects/gitTrendApp.webp";
import ctfImage from "../assets/projects/ctfImage.webp";
import personalWebsiteImage from "../assets/projects/personalWebsiteImage.webp";
import homeServerImage from "../assets/projects/homeServer.webp";
import kmmImage from "../assets/projects/kmmImage.webp";
import mindleImage from "../assets/projects/mindleImage.webp";

import {
  CelebrationOutlined,
  HomeOutlined,
  LocalBarOutlined,
} from "@mui/icons-material";
import ProjectCard from "../components/ProjectCard";

function Work() {
  const iconStyle = (iconColor: string) => ({
    fontSize: "2.2rem",
    "& path": {
      color: iconColor,
      //stroke: cardColor, // Need to be same as background color
      //strokeWidth: 0.8, // Customize the border thickness
    },
  });

  return (
    <>
      <Header
        title="Work."
        position="top-page-header"
        description="My professional journey through past experiences and workplaces."
        descriptionSize="small"
      />

      <WorkplaceCard
        jobTitle="SWE Internship"
        workplace="Populum"
        startDate="JUN 2024"
        endDate="AUG 2024"
        jobLocation="Stockholm, Sweden"
        jobWebsite="populum.io"
        jobLogo={populumLogo}
        logoAspectRatio={4158 / 830}
        jobDescription="Developed and enhanced Populum’s Saas platform for employee 
        engagement and workplace analytics, focusing on full-stack development to 
        improve user engagement and operational efficiency with robust, scalable and 
        user-friendly frontend and backend solutions."
        tools={["TypeScript", "Vue", "C#", ".NET"]}
        openByDefault={true}
      />

      <WorkplaceCard
        jobTitle="SWE Internship"
        workplace="Axis Communications"
        startDate="JUN 2023"
        endDate="AUG 2023"
        jobLocation="Linköping, Sweden"
        jobWebsite="axis.com"
        jobLogo={axisLogo}
        logoAspectRatio={1280 / 461}
        jobDescription="During my summer internship at Axis Communications, 
        I developed a Monitoring system designed for officers utilizing 
        body cameras. Collaborating with a skilled team, I gained practical experience 
        and insights into the intricacies of developing cutting-edge solutions within 
        a leading technology."
        tools={["TypeScript", "React", "Python", "Cosmos DB"]}
        openByDefault={false}
      />

      <WorkplaceCard
        jobTitle="Bachelor Thesis"
        workplace="Personalkollen"
        startDate="JAN 2023"
        endDate="JUN 2023"
        jobLocation="Linköping, Sweden"
        jobWebsite="personalkollen.se"
        jobLogo={personalkollenLogo}
        logoAspectRatio={800 / 214}
        jobDescription="Collaborated with Personalkollen as a Software Developer, 
        leading the development of a CRM system aimed at enhancing customer service. 
        Played a key role in designing and implementing solutions, designed to enhance
        the operational efficiency of customer support within the organization."
        tools={["JavaScript", "React", "Python", "Django", "Postgresql"]}
        openByDefault={false}
      />

      <Header
        title="Projects."
        position="mid-page-header"
        description="A collection of my diverse and innovative personal projects."
        descriptionSize="small"
      />

      <div className="projectCardContainer">
        <ProjectCard
          projectImage={personalWebsiteImage}
          imageWidth={"300px"}
          cardColor="#b9e9b9"
          hoverColor="#8ac78a"
          title="Personal website"
          shortDescription="My personal website, built with React to showcase my software 
          engineering skills and projects."
          longDescription="Developed a personal portfolio website using React, TypeScript, and 
          NodeJS Express. Featuring diverse projects, this platform demonstrates my technical 
          expertise and passion for innovation in the field."
          projectLink="https://github.com/anton-bergman"
        />

        <ProjectCard
          projectImage={homeServerImage}
          imageWidth={"300px"}
          cardColor="#b7e0ff"
          hoverColor="#a6cdf7"
          title="Home Server"
          shortDescription="I built a self-hosted home server which serves as a hub for my creative projects."
          longDescription="I built a self-hosted home server utilizing reverse proxies, SSH, 
          Docker containers and other tools for versatile experimentation and project realization. Empowering 
          innovation and creativity through seamless hosting, maintenance, and setup of diverse 
          applications and services."
        />

        <ProjectCard
          projectImage={mindleImage}
          imageWidth={"300px"}
          cardColor="#d095e5"
          hoverColor="#aa6bcf"
          title="Mindle"
          shortDescription="A Next.JS and Firebase web-app combining daily word-guessing games."
          longDescription="Developed Mindle, a Next.js and Firebase web app merging Wordle, 
          Ordle, and Stepdle. Users can enjoy word challenges, track statistics, and compete 
          with others. Deployed on Vercel for seamless user experience."
          projectLink="https://github.com/anton-bergman/mindle"
        />

        <ProjectCard
          projectImage={kmmImage}
          imageWidth={"300px"}
          cardColor="#ffb6c1"
          hoverColor="#ff9aa6"
          title="Autonomous RC Car"
          shortDescription="Built an autonomous RC car with LiDAR, Raspberry Pi, 
          and AVR, featuring object detection."
          longDescription="Developed an autonomous RC car using LiDAR, Raspberry Pi, 
          and AVR microcontrollers. Integrated object detection, control algorithms, 
          and a GUI for navigation, enabling the car to evade obstacles and maneuver 
          around a course autonomously."
          projectLink="https://github.com/anton-bergman/autonomous-rc-car"
          imageMarginTop={30}
          imageMarginLeft={30}
        />

        <ProjectCard
          projectImage={gitTrendImage}
          imageWidth={"150px"}
          cardColor="#e7b89c"
          hoverColor="#d89f7b"
          title="GitTrend"
          shortDescription="
          Flutter GitTrend app: discover top GitHub repos and explore details within the app."
          longDescription="Created a Flutter app, GitTrend, displaying top trending GitHub 
          repositories, offering sorting by stars, language, and creation date, with in-app 
          access to detailed repo features."
          projectLink="https://github.com/anton-bergman/git-trend"
        />

        <ProjectCard
          projectImage={ctfImage}
          imageWidth={"270px"}
          cardColor="#a3dcd4"
          hoverColor="#8bc6bd"
          title="Capture the flag"
          shortDescription="
          Created a captivating Capture the Flag game in Python with Pygame, highlighting 
          gameplay and design."
          longDescription="Developed an immersive Capture the Flag game using Python with 
          Pygame, showcasing strategic gameplay, dynamic challenges, and interactive features. 
          Leveraged Python's versatility and Pygame's graphical capabilities to create an 
          engaging gaming experience."
          projectLink="https://github.com/anton-bergman/capture-the-flag"
        />
      </div>

      <Header
        title="Extracurriculars."
        position="mid-page-header"
        description="In addition to my studies, I have devoted myself to various 
        extracurricular activities throughout my tenure at Linköping University."
        descriptionSize="small"
      />

      <div className="extraCardsContainer">
        <div className="card1">
          <HobbyCard
            title="D-Group"
            workplace="Datateknologsektionen"
            startDate="JUN 2021"
            endDate="JUN 2022"
            description="As a member of D-Group, I played a key role in planning, coordinating, 
            and executing diverse social events for all students at Linköping University. 
            Demonstrated strong organizational skills as a Logistics Coordinator for a 13-member 
            university committee, successfully overseeing major events, including Northern Europe’s 
            largest amateur darts competition, DÖMD, generating over 1.5 million SEK in revenue with 
            700 participants."
            width="20vw"
            iconBackgroundColor="#b7e0ff"
            icon={
              <CelebrationOutlined className="icon" sx={iconStyle("#023e8a")} />
            }
          />
        </div>

        <div className="card2">
          <HobbyCard
            title="Day Manager"
            workplace="Kårservice, Kårallen"
            startDate="JUN 2022"
            endDate="JUN 2023"
            description="Part-time employment as a Day Manager at the largest student union
            building at the university. Responsible for daily operations ensuring facility management, 
            staff coordination, bookings, and compliance with alcohol-serving permits/licenses, pivotal 
            for seamless operations and facility readiness."
            width="35vw"
            iconBackgroundColor="#e99394"
            icon={<HomeOutlined className="icon" sx={iconStyle("#9d0208")} />}
          />
        </div>

        <div className="card3">
          <HobbyCard
            title="Pub Committee"
            workplace="Datateknologsektionen"
            startDate="SEP 2020"
            description="As a member of the Pub Committee I actively contribute to planning and 
            executing engaging social events. Responsibilities included event coordination, 
            marketing initiatives, vendor collaboration, and ensuring a welcoming experience 
            for attendees, demonstrating organizational and teamwork skills in a dynamic social setting."
            width="35vw"
            iconBackgroundColor="#f9dc5c"
            icon={
              <LocalBarOutlined className="icon" sx={iconStyle("#b69121")} />
            }
          />
        </div>
      </div>
    </>
  );
}

export default Work;
